.table-wrapper {
    display: flex;
    flex-direction: column;

    margin-top: -2px;
    overflow: hidden;

    height: 100%;
}
.table-wrapper-grid {
    flex-direction: column;
    margin-top: -2px;
    display: grid;
    overflow: auto;
    height: calc(100vh - 160px);
    width: calc(100vw - 60px);
    border: 1px solid #ddd;
    border-radius: 5px;
}

.table-wrapper-grid  .ui.table {
    border: 0;
}

.table-wrapper-grid-130 {
    height: calc(100vh - 130px)!important;
}
.table-wrapper-grid-175 {
    height: calc(100vh - 175px)!important;
}
.table-wrapper-grid-290 {
    height: calc(100vh - 290px)!important;
    margin-top: 0!important;
}

.table-max-height-175 {
    max-height: calc(100vh - 175px)!important;
    height: auto !important;
}

.table-header {
    min-height: 78px;
}
.table-header-table {
    z-index: 2;
    border-bottom: 0px;
    background: none !important;
}
.table-header-table tr:first-child {
    height: 39px;
}
.table-header-table tr:first-child th {
    padding-top: 8px;
    padding-bottom: 8px;
}
.table-header-fixed .table-header-fixed__title {
    position: sticky!important;
    top: 0;
    z-index: 3;
}

.table-header-fixed tr th:not(.table-first-col) {
    min-width: 80px;
}
.table-header-fixed .table-header-fixed__title-fmid {
    position: sticky!important;
    top: 0;
    left: 45px;
    border-right: 1px solid rgba(34, 36, 38, .1);
    z-index: 10;
}
.table-header-fixed .table-header-fixed__title-fmid:nth-child(3), .table-header-fixed .table-header-fixed__filter-fmid:nth-child(3), .table-item-fmid-sticky:nth-child(3) {
    left: 159px;
}
.grid-auctions .table-header-fixed .table-header-fixed__title-fmid:nth-child(3), .grid-auctions .table-header-fixed .table-header-fixed__filter-fmid:nth-child(3), .grid-auctions .table-item-fmid-sticky:nth-child(3) {
    left: 124px;
}
.table-header-fixed .table-header-fixed__filter {
    position: sticky!important;
    top: 46px;
    border-bottom: 1px solid #ddd;
    z-index: 3;
}
.table-header-fixed .table-header-fixed__filter-fmid {
    position: sticky!important;
    top: 46px;
    left:45px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid rgba(34, 36, 38, .1);
    z-index: 10;
}
.table-data {
    margin-top: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgba(34, 36, 38, .1);
    /* box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); */
    flex-grow: 1;
}
.table-footer {
    font-size: 12px;
    font-weight: bold;
}
.table-scroll {
    overflow: hidden;
    overflow-y: scroll;
}
.table-scroll-grid {
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: auto;
}

.table-link {
    text-decoration: underline;
    color: #4183c4;
}

.table-link-green {
    color: #21ba45!important;
}

.table-link-grey {
    color: #747474!important;
}

.table-link:hover {
    cursor: pointer;
}



.table-filter-cell {
    height: 35px;
    padding: 0 !important;
}

.table-clear-filters:hover {
    opacity: 0.9;
    cursor: pointer;
}

.table-first-col {
    width: 60px !important;
    text-align: center !important;
    /*z-index: 6 !important;*/
}

.table-first-col div {
    margin: 5px;
}

.table-select-all-cell {
    z-index: 6 !important;
    /* background: #f9fafb; */
    border-bottom: none !important;
}

.table-item-sticky {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 5;
    background-color: #fff;
}

.table-item-fmid-sticky {
    position: sticky;
    left: 45px;
    top: 1px;
    z-index: 5;
    background-color: #fff;
    border-right: 1px solid rgba(34,36,38,.1);
}
.table-select-clear-filter {
    position: sticky;
    width: 60px !important;
    top: 46px;
    left: 0;
    z-index: 6;
}

.table-select-all-cell:hover {
    background: #f9fafb !important;
}

.table-first-col>.ui.fitted.checkbox {
    vertical-align: middle;
}

.table-first-col>.ui.fitted.checkbox>label::before {
    border-radius: 0;
}

.ui.table thead th.table-header-cell {
    border-bottom: 0 !important;
}



.table-empty {
    /* background-color: #ebedef; */
    background-image: url(/img/bgn10.png);
    background-attachment: fixed;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -100px;
    /* background-blend-mode: overlay; */
}

span.table-document:hover {
    color: #2185d0;
    cursor: pointer;
}

/* @media (min-width: 1000px) {
    .table-data {
        height: calc(100vh - 207px);
    }
} */

.ui.table thead tr:first-child>th:first-child, .ui.table thead tr:first-child>th:last-child {
    border-radius: 0 !important;
}

.ui.progress {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
}

.ui.progress>.bar {
    border-radius: 0 !important;
}
.table-data .ui.label {
    min-width: 50px;
    font-size: 11px;
}

.no-rows-wrapper {
    margin-top: 30px;
    margin-left: 30px;
    padding: 30px;
    width: 300px;
    height: 100px;
    /* background-color: #25242147; */
}

.no-rows {
    /* color: white; */
    font-size: 18px;
}

.banners {
    /*position: fixed;
    top: 15px;
    right: 460px;
    z-index: 30;
    max-width: 700px;*/
}

.banners > .message {
    background: #0e4194 !important;
    opacity: 1!important;
    color: white !important;
}

.banners .content > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.banners .content button {
    margin: 12px 24px;
}

.banner-close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.banner-close:hover {
    cursor: pointer;
}

.filter-timeslot input {
    margin-left: 10px;
    margin-right: 10px;
}
